// @flow
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FixedSizeGrid } from 'react-window';
import { companiesByOrganizationSelector, loadedSelector, searchTermSelector, searchTextSelector } from 'domain/companies';
import { loadingSelector, sagaDataLoadingSelector } from 'domain/env';
import { SEARCH_MIN_CHARACTERS } from 'domain/router/helpers';

import Box from '@mui/material/Box';
import GridCard from 'pages/companies/components/Grid/Card/GridCard';
import NotFound from 'pages/companies/components/NotFound';
import EmptyCompaniesList from 'pages/companies/components/EmptyCompaniesList';
import { useTheme } from '@mui/material';

const mapStateToProps = (state) => ({
  searchTerm: searchTermSelector(state),
  searchText: searchTextSelector(state),
  isSagaDataLoading: sagaDataLoadingSelector(state),
  list: companiesByOrganizationSelector(state),
  isLoading: loadingSelector(state),
  loaded: loadedSelector(state),
});

const MIN_CARD_WIDTH = 180;
const CARDS_GAP = 16;
const ROW_HEIGHT = 240;

const CompaniesGrid = () => {
  const containerRef = useRef();
  const { direction } = useTheme();

  const [widthDifference, setWidthDifference] = useState(0);
  const [listConfig, setListConfig] = useState({
    width: 0,
    height: 0,
    columnCount: 0,
    columnWidth: 0,
    rowHeight: 0,
    rowCount: 0,
  });
  const { list, isLoading, isSagaDataLoading, loaded, searchTerm, searchText } = useSelector(mapStateToProps);

  useEffect(() => {
    const calculate = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        const columnCount = Math.floor((width + CARDS_GAP) / (MIN_CARD_WIDTH + CARDS_GAP));
        const columnWidth = (width + CARDS_GAP - columnCount * CARDS_GAP) / columnCount;

        if (widthDifference === 0) {
          setWidthDifference(window.innerWidth - width);
        }

        setListConfig({
          width: widthDifference ? window.innerWidth - widthDifference : width,
          height,
          columnCount,
          columnWidth,
          rowHeight: ROW_HEIGHT,
          rowCount: Math.ceil(list.size / columnCount),
        });
      }
    };

    calculate();
    window.addEventListener('resize', calculate);

    return () => window.removeEventListener('resize', calculate);
  }, [list.size, widthDifference]);

  const emptyCompaniesListWithSearch = list.size === 0 && !isLoading && !isSagaDataLoading && loaded && (searchTerm?.length > 0 || searchText.length > SEARCH_MIN_CHARACTERS - 1) ;
  const emptyCompaniesListWithoutSearch = list.size === 0 && !isLoading && !isSagaDataLoading && loaded && searchTerm?.length === 0;

  switch (true) {
    case emptyCompaniesListWithSearch:
      return <NotFound />;
    
    case emptyCompaniesListWithoutSearch:
      return <EmptyCompaniesList />;
  
    default:
      return (
        <Box ref={containerRef} flex={1} my={2} maxWidth="100%">
          {!!list.size && (
            <FixedSizeGrid
              {...listConfig}
              itemData={{ list, gap: CARDS_GAP, columnCount: listConfig.columnCount }}
              direction={direction}
            >
              {GridCard}
            </FixedSizeGrid>
          )}
        </Box>
      );
  };
};

export default CompaniesGrid;

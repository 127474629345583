// @flow
import React, { useState, useEffect, useContext } from 'react';

import EmptyContainer from './components/EmptyContainer';
import CompanyContext from 'pages/company/CompanyContext';

import noDocs from './images/noDocs.png';
import noDocsConfidential from './images/noDocsConfidential.png';

import CelebrationOutlinedIcon from '@mui/icons-material/CelebrationOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
import RocketOutlinedIcon from '@mui/icons-material/RocketOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';

type Props = {
  random?: boolean,
  link?: string,
  onClick: () => void,
};

const empty = {
  img: '',
  Icon: null,
  title: '1',
  defaultTitle: '1',
  description: '1',
  defaultDescription: '1',
};

const noDocuments = {
  img: noDocs,
  Icon: null,
  title: 'document.search.no_documents',
  defaultTitle: 'No documents yet',
  description: 'document.search.no_document_description',
  defaultDescription: "We see that you haven't added any document yet",
};

const screens = [
  {
    img: '',
    Icon: CelebrationOutlinedIcon,
    title: 'document.search.caught.title',
    defaultTitle: 'All caught up!',
    description: 'document.search.caught.description',
    defaultDescription: 'You’ve done all work here. Please, enjoy your day!',
  },
  {
    img: '',
    Icon: EmojiEventsOutlinedIcon,
    title: 'document.search.wellDone.title',
    defaultTitle: 'Well done!',
    description: 'document.search.wellDone.description',
    defaultDescription: 'You do not have any documents to work here.',
  },
  {
    img: '',
    Icon: RocketOutlinedIcon,
    title: 'document.search.great.title',
    defaultTitle: 'You are doing great!',
    description: 'document.search.great.description',
    defaultDescription: 'There is no more work for you.',
  },
  {
    img: '',
    Icon: StarBorderOutlinedIcon,
    title: 'document.search.keepItUp.title',
    defaultTitle: 'Keep it up!',
    description: 'document.search.keepItUp.description',
    defaultDescription: 'You’ve finished all the documents and there is no work left for you.',
  },
  {
    img: '',
    Icon: MilitaryTechOutlinedIcon,
    title: 'document.search.congrats.title',
    defaultTitle: 'Congratulations!',
    description: 'document.search.congrats.description',
    defaultDescription: 'You’ve finished all the documents here.',
  },
  /* {
    img: done,
    title: 'document.search.done.title',
    defaultTitle: 'All done',
    description: 'document.search.done.description',
    defaultDescription: 'Welcome to your tidy workspace!',
  }, */
];

const confidentialScreen = {
  img: noDocsConfidential,
  Icon: null,
  className: 'confidential',
  title: 'document.search.confidential.title',
  defaultTitle: 'There is a place for confidential documents',
  description: 'document.search.confidential.description',
  defaultDescription: "You can send it to accountants, but other members of your company don't see it.", //eslint-disable-line
};

const NoDocuments: React$StatelessFunctionalComponent<Props> = ({ random = false, link, onClick }) => {
  const [screen, setScreen] = useState(empty);
  const { companyType } = useContext(CompanyContext);

  useEffect(() => {
    const currentScreen = random ? screens[Math.floor(Math.random() * screens.length)] : noDocuments;
    setScreen(companyType === 'confidential' ? confidentialScreen : currentScreen);
  }, [random, companyType]);

  return (
    <EmptyContainer
      link={random ? link : ''}
      imageSrc={screen.img}
      Icon={screen.Icon}
      title={{ id: screen.title, defaultMessage: screen.defaultTitle }}
      description1={{
        id: screen.description,
        defaultMessage: screen.defaultDescription,
      }}
      buttonText={{
        id: random ? 'document.search.workspace.go_to_all' : 'document.search.no_document.button',
        defaultMessage: random ? 'Go to all documents' : 'Add new document',
      }}
      onClick={onClick}
    />
  );
};

export default NoDocuments;

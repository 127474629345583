// @flow
import { selector } from 'lib/selectors';
import { GroupTodoItems, complitedTodoFilter as completedFilter } from 'domain/dashboard/helpers';

const dashboard = (state) => state.dashboard;
const documents = (state) => state.documents;
export const dashboardSelector = selector(dashboard, (d) => d);
export const processingDocsCountSelector = selector(documents, (d) => d.processing);
export const respondedSelector = selector(dashboard, (d) => d.responded);
// eslint-disable-next-line max-len
export const isDisablingFinAllowedSelector = selector(dashboard, (d) => d.processing === 0 && d.total.fin === 0);
export const dashboardTotalSelector = selector(documents, (d) => d.company_total);

const favorites = (state) => state.favorites;
export const favoritesListSelector = selector(favorites, (f) =>
  f.sort((a, b) => a.type === 'saved' || a.label.localeCompare(b.label)).toList(),
);

const todos = (state) => state.todos;
export const todosSelector = selector(todos, (t) => GroupTodoItems(t.toList()));
export const completedTodosSelector = selector(todos, (t) => t.toList().filter(completedFilter));
